<template>
  <div>
    <!-- filter section -->
    <b-card>
      <b-row v-if="form.product_sub_status === 'ready'">
        <b-col lg="4">
          <b-form-group label="ชื่ออุปกรณ์ | Serial">
            <b-form-input
              v-model="form.product"
              placeholder=""
              autocomplete="off"
              @keyup.enter="search"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="Order ID">
            <b-form-input
              v-model="form.order"
              placeholder=""
              autocomplete="off"
              @keyup.enter="search"
            />
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <b-form-group label="ชื่อลูกค้า | เบอร์โทร | Email">
            <b-form-input
              v-model="form.member"
              placeholder=""
              autocomplete="off"
              @keyup.enter="search"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group>
            <h5 class="mb-0 mt-1">สถานะการใช้เช่า</h5>
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.product_sub_status"
                name="some-radios2"
                value="ready"
              >
                พร้อมให้เช่า
              </b-form-radio>
              <b-form-radio
                v-model="form.product_sub_status"
                name="some-radios2"
                value="repair"
              >
                ส่งซ่อม
              </b-form-radio>
              <b-form-radio
                v-model="form.product_sub_status"
                name="some-radios2"
                value="lost"
              >
                หาย
              </b-form-radio>
              <b-form-radio
                v-model="form.product_sub_status"
                name="some-radios2"
                value="disable"
              >
                งดให้เช่า
              </b-form-radio>
              <b-form-radio
                v-model="form.product_sub_status"
                name="some-radios2"
                value="sold"
              >
                ขายแล้ว
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
        <b-col>
          <div
            class="text-right"
            style="
              display: flex;
              justify-content: flex-end;
              align-items: end;
              height: 100%;
            "
          >
            <!-- <div class="d-flex justify-content-between"> -->
            <b-button variant="info" @click="clear" class="mr-1"
              >Clear</b-button
            >
            <b-button
              variant="success"
              @click="load_data"
              class="mr-1"
              :disabled="loading"
            >
              <b-spinner small v-if="loading" /> แสดงทั้งหมด
            </b-button>
            <b-button
              variant="info"
              @click="load_data_only_booking"
              class="mr-1"
              :disabled="loading"
            >
              <b-spinner small v-if="loading" /> เฉพาะมีคิว
            </b-button>
            <b-button variant="success" @click="search" :disabled="loading">
              <b-spinner small v-if="loading" /> Search
            </b-button>
            <!-- </div> -->
          </div>
        </b-col>
      </b-row>
    </b-card>
    <!-- filter section END -->

    <!-- queue list  -->
    <b-overlay :show="loading" rounded="sm" class="dark-layout" variant="dark">
      <b-card>
        <div v-for="x in queue" :key="x._id">
          <b-row :class="{ 'bg-light-red': x.product_status !== 'ready' }">
            <b-col cols="12" md="4" class="product-details mb-1">
              <h4>
                {{ x.product.name }}
                <b-badge class="mr-1" variant="success" v-if="x.product.is_kit">
                  จัดชุด
                </b-badge>
              </h4>
              <b-button
                variant="relief-success"
                class="btn-icon text-right mr-1"
                @click="open_product(x.product_id)"
                size="sm"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
              <span> Serial: {{ x.serial }}</span>
              <span>No.{{ x.number }}</span> <br />
              <span>ค่าเช่า: {{ x.product.rental_price }} บาท</span> <br />
              ( {{ x.product.deposit_1 | number }} ||
              {{ x.product.deposit_2 | number }} )
              <h4 class="mt-1" v-if="x.product_status !== 'ready'">
                <b-badge variant="danger">{{ x.product_status }}</b-badge>
              </h4>
            </b-col>
            <b-col>
              <!-- :border-variant="border_color(y.order.order_status)" -->
              <b-card
                :border-variant="border_color(y.lock_status)"
                bg-variant="transparent"
                class="shadow-none mb-1"
                v-for="y in x.booking"
                :key="y._id"
              >
                <b-row>
                  <b-col cols="6" md="6">
                    <div v-if="y.member">
                      {{ y.member.first_name }} {{ y.member.last_name }}
                      <span v-if="y.member.retro_username">
                        ( {{ y.member.retro_username }} )
                      </span>
                      <span v-else-if="y.member.gen_username">
                        ( {{ y.member.gen_username }} )
                      </span>
                      <br />
                      {{ y.member.phone }} <br />
                    </div>
                    <div v-else>
                      <b-badge variant="danger"> ไม่มีข้อมูลผู้เช่า </b-badge>
                    </div>
                  </b-col>
                  <b-col cols="6" md="4">
                    <span class="mr-2">
                      {{ y.start_date | day }}
                      <b-badge :variant="badge_variant(y.start_date)">
                        {{ y.start_date | time }}
                      </b-badge>
                    </span>
                    <span>
                      {{ y.end_date | day }}
                      <b-badge :variant="badge_variant(y.end_date)">
                        {{ y.end_date | time }}
                      </b-badge>
                    </span>
                  </b-col>
                  <b-col cols="6" md="2">
                    <b-button
                      variant="relief-success"
                      class="btn-icon text-right"
                      @click="open_new(y.order_id)"
                      size="sm"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                    <br />
                    <small>{{ y.order_id_short }}</small>
                  </b-col>
                  <b-col cols="6" md="4">
                    <b-badge
                      class="mr-1 mb-1"
                      :variant="order_status_text(y.order_status).variant"
                    >
                      {{ order_status_text(y.order_status).text }}
                    </b-badge>
                    <b-badge
                      :variant="pickup_method_text(y.pickup_method).variant"
                      class="mr-1"
                    >
                      {{ pickup_method_text(y.pickup_method).text }}
                    </b-badge>
                    <b-badge variant="danger" v-if="!y.lock_status">
                      no-lock
                    </b-badge>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col sm="12" v-if="x.product_status === 'ready'">
              <hr style="border-top-width: 3px"
            /></b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
    <!-- queue list END -->
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BBadge,
  BButton,
  BLink,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BSpinner,
  BOverlay,
} from "bootstrap-vue";
import moment from "moment";
import thai_text from "@/assets/thai_text.json";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BBadge,
    BButton,
    BLink,
    BFormGroup,
    BFormInput,
    thai_text,
    BFormRadio,
    BSpinner,
    BOverlay,
  },
  data() {
    return {
      queue: [],
      form: {
        product_sub_status: "ready",
      },
      loading: false,
    };
  },
  created() {
    // this.load_data();
  },
  methods: {
    badge_variant(dateTime) {
      const time = moment(dateTime).format("HH:mm");
      // console.log(time);
      if (time === "11:30" || time === "13:00") {
        return "light-info";
      } else if (time === "14:00" || time === "16:00") {
        return "light-warning";
      } else if (time === "17:00" || time === "19:00") {
        return "light-success";
      }
      // 1 info
      // 2 warning
      // 3 success
      return "light-success";
    },
    load_data_only_booking() {
      this.loading = true;
      this.$http({
        method: "POST",
        url: `/queue/list/`,
        data: {
          only_booking: true,
        },
      })
        .then((x) => {
          this.queue = x.data.data; ///.slice(0, 10);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.loading = false;
          this.toast_error("Error", e.response.data.error);
        });
    },
    load_data() {
      this.loading = true;
      this.$http({
        method: "POST",
        url: `/queue/list/`,
        data: {
          only_booking: false,
        },
      })
        .then((x) => {
          this.queue = x.data.data; ///.slice(0, 10);
          this.loading = false;
          // console.log("sizeInBytes", this.queue);
          // const jsonString = JSON.stringify(this.queue);
          // const sizeInBytes = new TextEncoder().encode(jsonString).length;
          // console.log("sizeInBytes", sizeInBytes);
        })
        .catch((e) => {
          this.loading = false;
          this.loading = false;
          this.toast_error("Error", e.response.data.error);
        });
    },
    clear() {
      this.form = {};
      this.queue = [];
    },
    search() {
      this.loading = true;
      this.$http({
        method: "POST",
        url: `/queue/search/`,
        data: this.form,
      })
        .then((x) => {
          this.loading = false;
          this.queue = x.data.data;
        })
        .catch((e) => {
          this.loading = false;
          this.toast_error("Error", e.response.data.error);
        });
    },
    order_status_text(value) {
      const temp = thai_text.order_status.find((x) => {
        return x.req === value;
      });
      if (temp) {
        return temp;
      } else {
        return {
          req: value,
          text: `ERROR ${value}`,
          variant: "danger",
          lock_queue: true,
        };
      }
    },
    pickup_method_text(value) {
      const temp = thai_text.pickup_method.find((x) => {
        return x.req === value;
      });
      if (temp) {
        return temp;
      } else {
        return { variant: "danger", text: `ERROR ${value}` };
      }
    },

    border_color(x) {
      if (x === true) {
        return "success";
      } else {
        return "danger";
      }
    },
    open_new(id) {
      window.open("/order/view/" + id, "_blank");
    },
    open_product(id) {
      window.open("/product/view/" + id, "_blank");
    },
  },
  filters: {
    day: function (date) {
      return moment(date).format("DD/MM");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
    number: function (x) {
      if (x != null) {
        let rounded = Number(x).toFixed(2);

        // Remove the decimal part if it's .00
        if (rounded.endsWith(".00")) {
          rounded = rounded.substring(0, rounded.length - 3);
        }
        return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>

<style>
@media (min-width: 768px) {
  .product-details span {
    display: block;
  }
}

.product-details {
  display: block;
  width: 100%;
}

.product-details span {
  display: inline-block;
  margin-right: 10px; /* Adds some space between inline elements */
}
.bg-light-red {
  border: 3px solid red;
  margin-top: 1em;
  margin-bottom: 2em;
  padding-top: 1em;
  border-radius: 10px;
}
</style>
